<template>
  <v-dialog
    v-model="internalShowing"
    max-width="800px"
  >
    <v-card>
      <v-card-title
        v-if="mostrarImporte"
      >
        <v-switch
          v-model="verImportesConIVA"
          label="Importes con IVA incluido"
        />
      </v-card-title>
      <v-card-text>
        <v-simple-table
          class="pt-4"
          dense
          fixed-header
        >
          <template #default>
            <thead>
              <tr>
                <th>Descripción</th>
                <th
                  class="text-right"
                >
                  Unidades
                </th>
                <th
                  v-if="mostrarImporte"
                  class="text-right"
                >
                  Subtotal
                </th>
                <th>Código</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(linea, index) in lineasFormatted"
                :key="index"
              >
                <td>
                  <v-badge
                    v-model="linea.noFacturable"
                    color="error"
                    inline
                  >
                    <template
                      #badge
                    >
                      no facturable
                    </template>
                    {{ linea.lparte_trabajo.descripcion }}
                  </v-badge>
                </td>
                <td
                  class="text-right"
                >
                  {{ linea.unidades|decimal }}
                </td>
                <td
                  v-if="mostrarImporte"
                  class="text-right"
                >
                  <span
                    v-show="verImportesConIVA"
                  >{{ linea.total|currency }}</span>
                  <span
                    v-show="!verImportesConIVA"
                  >{{ linea.biDto|currency }}</span>
                </td>
                <td>{{ linea.lparte_trabajo.codigo }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click.stop="clickCerrar"
        >
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'
import _ from '@/utils/lodash'

export default {
  mixins: [internalShowingMixin],
  props: {
    lineas: {
      type: Array,
      default: () => [],
    },
    mostrarImporte: {
      type: Boolean,
      default: false,
    },
    conIVA: {
      type: Boolean,
      default: true,
    },
    conRecargo: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      verImportesConIVA: true,
    }
  },
  computed: {
    lineasFormatted () {
      const lineas = _.cloneDeep(this.lineas)
      for (let linea of lineas) {
        const precio = linea.lorden_trabajo.precio
        const iva = linea.lorden_trabajo.iva
        const recargo = linea.lorden_trabajo.recargo
        linea.noFacturable = !linea.lparte_trabajo.facturar
        linea.bi = precio * linea.unidades
        linea.biDto = linea.bi - (linea.bi * linea.lorden_trabajo.dto / 100)
        if (linea.biDto > 0) {
          linea.total = linea.biDto + (iva / 100 * linea.biDto) + (recargo / 100 * linea.biDto)
        } else {
          linea.total = 0
        }
      }
      return lineas
    }
  },
  methods: {
    clickCerrar () {
      this.internalShowing = false
    },
  },
}
</script>
